// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import './plugins/pagination';

// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  // Ajax Pagination
  $('.js-all-episodes').each(function () {
    ajaxPosts($(this));
  });

  /**
   * Smooth scroll to anchor
   */
  $(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();

    if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
      $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
    }

    $('html, body').animate(
      {
        scrollTop: $($.attr(this, 'href')).offset().top,
      },
      1000
    );
  });

  //Pop-over window
  showPodcastPopOverWindow();

  /**
   * Close Podcast Form PopUp After Submit Form
   */
  $(document).on('gform_confirmation_loaded', function () {
    let $podcastFormPopUp = $('.podcast-form');

    if ($podcastFormPopUp.length) {
      setTimeout(function () {
        $podcastFormPopUp.fadeOut(500);
      }, 2000);
    }
  });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});

/**
 * Scripts which Ajax Pagination
 */
function ajaxPosts(section) {
  // const loadmore = section.find('.js-loadmore');
  const postsRoot = section.find('.post-grid');
  if (!postsRoot.length) return;
  const paginationContainer = section.find('.js-pagination');
  const postType = postsRoot.attr('data-post-type');
  const postsPerPage = postsRoot.attr('data-posts-per-page');

  const errorMarkup =
    '<p class="h2 ajax-error">Something went wrong. Please, check your connection, refresh the page and try again.</p>';

  let currentPage = 1;
  let isLoading = false;

  function postsPagination(maxPages) {
    paginationContainer.pagination({
      // the number of entries
      total: maxPages ? maxPages : paginationContainer.attr('data-max-pages'),
      // current page
      current: 1,
      // the number of entires per page
      length: 1,
      // pagination size
      size: 1,
      // Prev/Next text
      prev:
        '<?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n' +
        '<svg fill="#fff" width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"><path d="M11.3,12l3.5-3.5c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-4.2,4.2l0,0c-0.4,0.4-0.4,1,0,1.4l4.2,4.2c0.2,0.2,0.4,0.3,0.7,0.3l0,0c0.3,0,0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L11.3,12z"/></svg>',

      next:
        '<?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->\n' +
        '<svg fill="#fff" width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"><path d="M9.9,17.2c-0.6,0-1-0.4-1-1c0-0.3,0.1-0.5,0.3-0.7l3.5-3.5L9.2,8.5c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l4.2,4.2c0.4,0.4,0.4,1,0,1.4c0,0,0,0,0,0l-4.2,4.2C10.4,17.1,10.1,17.2,9.9,17.2z"/></svg>',

      click: function (options) {
        currentPage = options.current;
        fetchPosts('replace', currentPage, postType, postsPerPage);
      },
    });
  }

  postsPagination();

  function fetchPosts(action, page, postType, postsPerPage) {
    if (isLoading) return;
    isLoading = true;
    const data = {
      action: 'fetch_posts',
      page,
      postType,
      postsPerPage,
    };
    $.ajax({
      type: 'POST',
      // eslint-disable-next-line no-undef
      url: ajax_object.ajax_url,
      dataType: 'json',
      data,
      beforeSend: function () {
        isLoading = true;
        if (action === 'replace') {
          paginationContainer.css('pointer-events', 'none');
          $('html, body').animate(
            {
              scrollTop: postsRoot.offset().top - 150,
            },
            800,
            () => $('html, body').attr('style', '')
          );
          postsRoot.css('height', postsRoot.outerHeight());
          postsRoot.children().fadeOut();
          $('.ajax-overlay').addClass('ajax-overlay--active');
        }
      },
      success: function (response) {
        if (action === 'replace') {
          postsRoot.html(response.html);
        }
      },
      complete: function () {
        isLoading = false;
        postsRoot.css('height', 'auto');
        paginationContainer.css('pointer-events', 'unset');
        $('.ajax-overlay').removeClass('ajax-overlay--active');
      },
      error: function (err) {
        // if (action === 'add') currentPage--;
        console.log(err);
        if (action === 'replace') {
          postsRoot.html(errorMarkup);
          paginationContainer.hide();
        }
      },
    });
  }
}

function showPodcastPopOverWindow() {
  const podcastForm = $('.podcast-form');
  const body = $('body');

  $(document).on('click', function (e) {
    if (e.target.classList.contains('podcast-button')) {
      podcastForm.fadeToggle(500);
      podcastForm.css('display', 'flex');
      body.css('overflow', 'hidden');
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    } else if (e.target.classList.contains('podcast-form__close')) {
      podcastForm.fadeOut(500);
      body.css('overflow', 'auto');
    } else if (e.target.classList.contains('podcast-form')) {
      podcastForm.fadeOut(500);
      body.css('overflow', 'auto');
    }
  });
}
